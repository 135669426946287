module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"creditonplus","preview":false,"prismicToolbar":false,"pages":[{"type":"Home","match":"/","path":"/","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/home.js"},{"type":"Medios de pago","match":"/medios-de-pago","path":"/medios-de-pago","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/mediosdepago.js"},{"type":"Promociones","match":"/promociones","path":"/promociones","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/promociones.js"},{"type":"Preguntas frecuentes","match":"/preguntas-frecuentes","path":"/preguntas-frecuentes","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/preguntas-frecuentes.js"},{"type":"Solicitud","match":"/solicitar-prestamo","path":"/solicitar-prestamo","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/solicitud.js"},{"type":"Terminos y condiciones","match":"/terminos-y-condiciones","path":"/terminos-y-condiciones","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/condiciones.js"},{"type":"Facturacion electronica","match":"/facturacion-electronica","path":"/facturacion-electronica","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/facturacion.js"},{"type":"buenas practicas","match":"/buenas-practicas","path":"/buenas-practicas","component":"/home/runner/work/crediton_plus/crediton_plus/src/templates/buenas-practicas.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":750},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
