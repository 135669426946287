/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it




exports.onRouteUpdate = () => {


      setTimeout(() => {
            headerInit();
            headerWaypointsInit();
            navMobileClose();
      }, 100)


      // HEADER INIT


      function headerInit() {

            var $header = document.getElementsByClassName('c-header')[0];
            var $btn = document.getElementsByClassName('js-show-nav')[0];
            var $nav = document.getElementsByClassName('js-nav')[0];
            var $btnFinanciera = document.getElementsByClassName('js-btn-financiera');
            var $btnCloseFinanciera = document.getElementsByClassName('js-close-info')[0];
            var $popupFinanciera = document.getElementsByClassName('js-popup-financiera')[0];
            var $popupWhataspp = document.getElementsByClassName('js-popup-whatsapp')[0];
            var $btnsWpp = document.getElementsByClassName('js-whatsapp-btn');
            var $btnCloseWhatsapp = document.getElementsByClassName('js-close-whatsapp');

            var $popupCondiciones = document.getElementsByClassName('js-popup-tyc')[0];
            var $btnsCondiciones = document.getElementsByClassName('js-condiciones-btn');
            var $btnCloseCondiciones = document.getElementsByClassName('js-close-condiciones')[0];


            var popupInTimer = null;
            var popupOutTimer = null;
            var popupToggleTimer = null;

            // Show Popup

            function showPopup($el) {

                  // Clear timers
                  clearTimeout(popupOutTimer);
                  popupOutTimer = null;
                  clearTimeout(popupToggleTimer);
                  popupToggleTimer = null;

                  // Display block to popup
                  $el.style.display = 'block';

                  $el.focus();



            } // showPopup

            // Hide Popup

            function hidePopup($el) {

                  // Reset Timers
                  clearTimeout(popupInTimer);
                  popupInTimer = null;
                  clearTimeout(popupToggleTimer);
                  popupToggleTimer = null;

                  // Set popup's Fixed style

                  // Hide popup after OUT animation
                  popupOutTimer = setTimeout(function () {

                        // Hide popup
                        $el.style.display = 'none';

                  }, 600);

            } // hidePopup



            if ($btn) {
                  $btn.onclick = function () {


                        if ($btn.classList.contains('is-active') == false) {

                              // Si el Form y Nav estÃ¡n cerrados
                              showPopup($nav, true);
                              // Show popup
                              $header.classList.add('is-open');
                              // Set Nav state
                              $btn.classList.add('is-active');

                        } else {

                              // Si estÃ¡ abierto el Form
                              hidePopup($nav, true);

                              // Show popup
                              $header.classList.remove('is-open');
                              // Set Nav state
                              $btn.classList.remove('is-active');

                        }
                        this.blur();

                        return false;
                  };
            }

            //POPUP INF-FINANCIERA

            if ($btnFinanciera) {
                  for (const btn of $btnFinanciera) {
                        btn.onclick = function () {

                              showPopup($popupFinanciera, true);
                              $popupFinanciera.classList.add('is-open');

                              this.blur();

                              return false;
                        };
                  }
            }
            if ($btnCloseFinanciera) {
                  $btnCloseFinanciera.onclick = function () {

                        hidePopup($popupFinanciera, true);
                        $popupFinanciera.classList.remove('is-open');

                        this.blur();

                        return false;
                  };
            }

            //POPUP WHATSAPP

            if ($btnsWpp) {
                  for (const btn of $btnsWpp) {
                        btn.onclick = function () {

                              this.getAttribute('data-promoid') ? localStorage.setItem('promoid',this.getAttribute('data-promoid')) : localStorage.setItem('promoid','');

                              showPopup($popupWhataspp, true);
                              $popupWhataspp.querySelectorAll('.c-form__input')[0].focus();
                              $popupWhataspp.classList.add('is-open');

                              this.blur();

                              return false;
                        };
                  }
            }
            if ($btnCloseWhatsapp) {
                  for (const btn of $btnCloseWhatsapp) {
                        btn.onclick = function () {

                              hidePopup($popupWhataspp, true);
                              $popupWhataspp.classList.remove('is-open');

                              this.blur();

                              return false;
                        };
                  }
            }

            //POPUP CONDICIONES

            if ($btnsCondiciones) {
                  for (const btn of $btnsCondiciones) {
                        btn.onclick = function () {

                              showPopup($popupCondiciones, true);

                              const txtCondiciones = btn.querySelector('.js-condiciones-selected').innerHTML;

                              $popupCondiciones.querySelector('.js-text-holder').innerHTML = txtCondiciones;


                              $popupCondiciones.classList.add('is-open');

                              this.blur();

                              return false;
                        };
                  }
            }
            if ($btnCloseCondiciones) {
                  $btnCloseCondiciones.onclick = function () {

                        hidePopup($popupCondiciones, true);
                        $popupCondiciones.classList.remove('is-open');

                        this.blur();

                        return false;
                  };
            }





      } // headerInit


      // Header waypointsInit
      function headerWaypointsInit() {
            // VARS
            var $whiteHeader = document.getElementById("c-header");
            // SECTION
            var $itemMenuWaypointsArray = document.querySelectorAll('.js-header-waypoints');
            Array.prototype.forEach.call($itemMenuWaypointsArray, function (el, i) {
                  // Vars
                  var $section = el;
                  var sectionId = $section.getAttribute('id');
                  // Down
                  new window.Waypoint({
                        element: document.getElementById(sectionId),
                        handler: function (direction) {
                              if (direction == 'down') {
                                    $whiteHeader.classList.add('is-fixed');
                              }

                        },
                        //offset: 220
                  });
                  // Up
                  new window.Waypoint({
                        element: document.getElementById(sectionId),
                        handler: function (direction) {
                              if (direction == 'up') {
                                    $whiteHeader.classList.remove('is-fixed');
                              }

                        },
                        //offset: 220
                  });
            });
      } // waypointsInit


      //Close nav when click nav-link
      function navMobileClose() {

            const navLinks = document.querySelectorAll(".c-nav__link");
            const $header = document.getElementById("c-header");

            for (const link of navLinks) {
                  link.addEventListener("click", function () {

                        $header.classList.remove('is-open');

                  });
            }

      }

      // Nav anchor animation

      const anclas = document.querySelectorAll(".js-nav__anchor");

      for (const ancla of anclas) {
            ancla.addEventListener("click", clickHandler);
      }

      function clickHandler(e) {
            e.preventDefault();
            const href = this.getAttribute("href");
            const offsetTop = document.querySelector(href).offsetTop - 60;


            window.scroll({
                  top: offsetTop,
                  behavior: "smooth"
            });

      }




}